/** @jsxImportSource theme-ui */

import * as React from "react"
import { graphql, Link } from "gatsby"

// import fonts
import "@fontsource/ibm-plex-sans";
import "@fontsource/ibm-plex-serif";

import Seo from "../components/seo";
import Layout from "../components/Layout";
import { Box, Button, Container, Flex, Heading, Paragraph, useColorMode } from "theme-ui";

const BlogIndex = ({ data }) => {
  const posts = data.allMarkdownRemark.nodes;

  const [colorMode, setColorMode] = useColorMode();
  const isDark = colorMode === `dark`;
  const toggleColorMode = (e) => {
    setColorMode(isDark ? `light` : `dark`);
  }

  return (
    <Layout>
      <Container sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '100vh',
      }}>
        <Flex sx={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '85vh' }}>
          <Seo title="Home - Malik Wahab"/>
          <Heading as="h1" variant="styles.h1">Hi, I'm Malik Wahab</Heading>
          <Paragraph as="p" variant="styles.p">A Programmer, Writer, Builder, and Tech Enthusiast. - I write about software development practice here.</Paragraph>
          <Flex sx={{ marginTop: 4, textDecoration: 'underline', justifyContent: 'center' }}>
            <Box as={Link} to={`/about`} sx={{ marginRight: 5 }} variant={`styles.h5`}>About Me</Box>
            <Box as={Link} to={`/blog`} variant={`styles.h5`}>Blog</Box>
          </Flex>
        </Flex>
        <Box>
          <Button
            sx={{
              variant: `buttons.toggle`,
              fontWeight: `semibold`,
              display: `block`,
              mx: `auto`,
              mb: 3
          }}
            onClick={toggleColorMode}
            type="button"
            aria-label="Toggle dark mode"
          >
            {isDark ? `Light` : `Dark`}
          </Button>
        </Box>
      </Container>
    </Layout>
  );
}

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          tags
          featured
        }
      }
    }
  }
`
